import React from "react";

const PackageIconoServiciosAlineacionBalanceo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2202 3.37695C9.27432 3.37695 8.50753 4.16244 8.50753 5.13139V18.8725C8.50753 19.8415 9.27432 20.627 10.2202 20.627H14.4816C15.4275 20.627 16.1943 19.8415 16.1943 18.8725V5.13139C16.1943 4.16244 15.4275 3.37695 14.4816 3.37695H10.2202ZM7.77539 5.13139C7.77539 3.74823 8.86996 2.62695 10.2202 2.62695H14.4816C15.8318 2.62695 16.9264 3.74823 16.9264 5.13139V18.8725C16.9264 20.2557 15.8318 21.377 14.4816 21.377H10.2202C8.86996 21.377 7.77539 20.2557 7.77539 18.8725V5.13139Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.94037 18.3872C7.08333 18.5336 7.08333 18.7711 6.94037 18.9175L5.26296 20.6358C5.12 20.7823 4.88822 20.7823 4.74526 20.6358C4.6023 20.4894 4.6023 20.2519 4.74526 20.1055L6.16381 18.6523L4.74526 17.1992C4.6023 17.0527 4.6023 16.8153 4.74526 16.6689C4.88822 16.5224 5.12001 16.5224 5.26297 16.6689L6.94037 18.3872Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.04759 18.6523C7.04759 18.8595 6.88369 19.0273 6.68152 19.0273L3.21431 19.0273C3.01213 19.0273 2.84823 18.8595 2.84823 18.6523C2.84823 18.4452 3.01213 18.2773 3.2143 18.2773L6.68152 18.2773C6.88369 18.2773 7.04759 18.4452 7.04759 18.6523Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1544 3.54446C20.2973 3.69091 20.2973 3.92835 20.1544 4.07479L18.7401 5.52351L20.1587 6.97666C20.3016 7.12311 20.3016 7.36055 20.1587 7.50699C20.0157 7.65344 19.7839 7.65344 19.641 7.50699L17.9636 5.78868C17.8206 5.64223 17.8206 5.40479 17.9636 5.25835L19.6367 3.54446C19.7796 3.39802 20.0114 3.39802 20.1544 3.54446Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0518 5.52739C22.0516 5.7345 21.8875 5.90218 21.6853 5.90192L18.2224 5.89749C18.0202 5.89723 17.8565 5.72913 17.8568 5.52202C17.8571 5.31492 18.0212 5.14723 18.2233 5.14749L21.6862 5.15192C21.8884 5.15218 22.0521 5.32029 22.0518 5.52739Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.32924 10.1277C3.33677 10.1272 3.34432 10.127 3.35187 10.127H5.67211C5.87428 10.127 6.03818 10.2948 6.03818 10.502V13.9779C6.03818 14.1851 5.87428 14.3529 5.67211 14.3529H3.35187C3.34432 14.3529 3.33677 14.3527 3.32924 14.3522L3.35187 13.9779C3.32924 14.3522 3.32901 14.3522 3.32877 14.3522L3.32829 14.3522L3.32727 14.3521L3.32503 14.3519L3.31974 14.3515C3.31585 14.3512 3.31125 14.3508 3.30599 14.3502C3.29546 14.3491 3.28221 14.3474 3.26661 14.3449C3.23551 14.34 3.19442 14.3319 3.14659 14.3184C3.0518 14.2916 2.9245 14.242 2.79579 14.1492C2.52006 13.9505 2.29492 13.5949 2.29492 13.026V11.4539C2.29492 10.885 2.52006 10.5294 2.79579 10.3307C2.9245 10.2379 3.0518 10.1883 3.14659 10.1615C3.19442 10.148 3.23551 10.1399 3.26661 10.135C3.28221 10.1325 3.29546 10.1308 3.30598 10.1297C3.31125 10.1291 3.31585 10.1287 3.31974 10.1284L3.32503 10.128L3.32727 10.1278L3.32829 10.1277L3.32877 10.1277C3.32901 10.1277 3.32924 10.1277 3.35187 10.502L3.32924 10.1277ZM3.37365 10.877C3.36639 10.8782 3.35519 10.8806 3.34125 10.8845C3.3065 10.8943 3.26108 10.9124 3.21707 10.9441C3.14737 10.9943 3.02706 11.1148 3.02706 11.4539V13.026C3.02706 13.3651 3.14737 13.4856 3.21707 13.5358C3.26108 13.5675 3.3065 13.5856 3.34125 13.5954C3.35519 13.5993 3.36639 13.6017 3.37365 13.6029H5.30604V10.877H3.37365Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.339 8.62572C6.34654 8.62524 6.35409 8.625 6.36164 8.625H8.14079C8.34297 8.625 8.50686 8.79289 8.50686 9V15.2818C8.50686 15.4889 8.34297 15.6568 8.14079 15.6568H6.36164C6.35409 15.6568 6.34654 15.6566 6.339 15.6561L6.36164 15.2818C6.339 15.6561 6.33877 15.6561 6.33854 15.6561L6.33806 15.6561L6.33704 15.656L6.3348 15.6558L6.3295 15.6554C6.32562 15.6551 6.32102 15.6546 6.31575 15.6541C6.30523 15.653 6.29197 15.6513 6.27637 15.6488C6.24528 15.6439 6.20419 15.6358 6.15635 15.6223C6.06157 15.5955 5.93427 15.5459 5.80556 15.4531C5.52982 15.2544 5.30469 14.8988 5.30469 14.3299V9.95198C5.30469 9.383 5.52982 9.02746 5.80556 8.82874C5.93427 8.73598 6.06157 8.68632 6.15635 8.65954C6.20419 8.64602 6.24528 8.6379 6.27637 8.633C6.29197 8.63055 6.30523 8.62888 6.31575 8.62775C6.32102 8.62719 6.32562 8.62676 6.3295 8.62643L6.3348 8.62601L6.33704 8.62585L6.33806 8.62578L6.33854 8.62575C6.33877 8.62573 6.339 8.62572 6.36164 9L6.339 8.62572ZM6.38341 9.375C6.37616 9.37628 6.36496 9.3786 6.35102 9.38254C6.31627 9.39236 6.27085 9.41042 6.22684 9.44214C6.15713 9.49237 6.03683 9.61282 6.03683 9.95198V14.3299C6.03683 14.669 6.15713 14.7895 6.22684 14.8397C6.27085 14.8714 6.31626 14.8895 6.35102 14.8993C6.36496 14.9032 6.37616 14.9056 6.38341 14.9068H7.77472V9.375H6.38341Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7701 4.0065C11.933 4.12919 11.9679 4.36391 11.8482 4.53077L10.632 6.22511L11.8482 7.91946C11.9679 8.08631 11.933 8.32104 11.7701 8.44372C11.6072 8.56641 11.3781 8.53061 11.2583 8.36375L9.88267 6.44726C9.78781 6.3151 9.78781 6.13512 9.88267 6.00296L11.2583 4.08647C11.3781 3.91962 11.6072 3.88381 11.7701 4.0065Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7701 7.84048C11.933 7.96317 11.9679 8.19789 11.8482 8.36475L10.6317 10.0595L11.8485 11.7602C11.968 11.9272 11.9327 12.1619 11.7696 12.2843C11.6066 12.4067 11.3775 12.3706 11.258 12.2035L9.88233 10.2808C9.7878 10.1486 9.78793 9.96893 9.88267 9.83695L11.2583 7.92046C11.3781 7.7536 11.6072 7.7178 11.7701 7.84048Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7701 11.6784C11.933 11.8011 11.9679 12.0358 11.8482 12.2026L10.632 13.897L11.8482 15.5913C11.9679 15.7582 11.933 15.9929 11.7701 16.1156C11.6072 16.2383 11.3781 16.2025 11.2583 16.0356L9.88267 14.1191C9.78781 13.987 9.78781 13.807 9.88267 13.6748L11.2583 11.7583C11.3781 11.5915 11.6072 11.5557 11.7701 11.6784Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7701 15.5124C11.933 15.635 11.9679 15.8698 11.8482 16.0366L10.632 17.731L11.8482 19.4253C11.9679 19.5922 11.933 19.8269 11.7701 19.9496C11.6072 20.0723 11.3781 20.0365 11.2583 19.8696L9.88267 17.9531C9.78781 17.821 9.78781 17.641 9.88267 17.5088L11.2583 15.5923C11.3781 15.4255 11.6072 15.3897 11.7701 15.5124Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9195 4.0065C15.0824 4.12919 15.1173 4.36391 14.9976 4.53077L13.7814 6.22511L14.9976 7.91946C15.1173 8.08631 15.0824 8.32104 14.9195 8.44372C14.7566 8.56641 14.5275 8.53061 14.4077 8.36375L13.0321 6.44726C12.9372 6.3151 12.9372 6.13512 13.0321 6.00296L14.4077 4.08647C14.5275 3.91962 14.7566 3.88381 14.9195 4.0065Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9195 7.84048C15.0824 7.96317 15.1173 8.19789 14.9976 8.36475L13.7811 10.0595L14.9979 11.7602C15.1174 11.9272 15.0821 12.1619 14.919 12.2843C14.756 12.4067 14.5269 12.3706 14.4074 12.2035L13.0317 10.2808C12.9372 10.1486 12.9373 9.96893 13.0321 9.83695L14.4077 7.92046C14.5275 7.7536 14.7566 7.7178 14.9195 7.84048Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9195 11.6784C15.0824 11.8011 15.1173 12.0358 14.9976 12.2026L13.7814 13.897L14.9976 15.5913C15.1173 15.7582 15.0824 15.9929 14.9195 16.1156C14.7566 16.2383 14.5275 16.2025 14.4077 16.0356L13.0321 14.1191C12.9372 13.987 12.9372 13.807 13.0321 13.6748L14.4077 11.7583C14.5275 11.5915 14.7566 11.5557 14.9195 11.6784Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9195 15.5124C15.0824 15.635 15.1173 15.8698 14.9976 16.0366L13.7814 17.731L14.9976 19.4253C15.1173 19.5922 15.0824 19.8269 14.9195 19.9496C14.7566 20.0723 14.5275 20.0365 14.4077 19.8696L13.0321 17.9531C12.9372 17.821 12.9372 17.641 13.0321 17.5088L14.4077 15.5923C14.5275 15.4255 14.7566 15.3897 14.9195 15.5124Z"
      fill="currentColor"
    />
  </svg>
);

export default PackageIconoServiciosAlineacionBalanceo;
