import React from "react";

const PackageIconoServiciosCambioFrentos = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <g clipPath="url(#clip0_3400_15025)">
      <path
        d="M16.923 18.2129C15.0194 18.2129 13.4453 16.6004 13.4453 14.6504C13.4453 12.7004 15.0194 11.0879 16.923 11.0879C18.8266 11.0879 20.4007 12.7004 20.4007 14.6504C20.4007 16.6004 18.8266 18.2129 16.923 18.2129ZM16.923 11.8379C15.4221 11.8379 14.1775 13.1129 14.1775 14.6504C14.1775 16.1879 15.4221 17.4629 16.923 17.4629C18.4239 17.4629 19.6685 16.1879 19.6685 14.6504C19.6685 13.1129 18.4239 11.8379 16.923 11.8379Z"
        fill="currentColor"
      />
      <path
        d="M16.9226 15.0273C16.8128 15.0273 16.6663 14.9523 16.5931 14.8398L12.127 6.96484H10.1503C9.93061 6.96484 9.78418 6.81484 9.78418 6.58984C9.78418 6.36484 9.93061 6.21484 10.1503 6.21484H12.3467C12.4931 6.21484 12.6029 6.28984 12.6761 6.40234L17.252 14.4648C17.3619 14.6523 17.2886 14.8773 17.1056 14.9898C17.0324 14.9898 16.9592 15.0273 16.9226 15.0273Z"
        fill="currentColor"
      />
      <path
        d="M15.6413 10.3391C15.6047 10.3391 15.6047 10.3391 15.5681 10.3391L13.9939 9.96406C13.8841 9.92656 13.8109 9.88906 13.7743 9.77656L12.8957 8.31406C12.8225 8.20156 12.8225 8.05156 12.8957 7.93906C12.9689 7.82656 13.0788 7.75156 13.1886 7.75156L15.6047 7.60156C15.7145 7.60156 15.7877 7.63906 15.8609 7.71406C15.9341 7.78906 15.9707 7.90156 15.9707 7.97656V9.96406C15.9707 10.0766 15.9341 10.1891 15.8243 10.2641C15.7877 10.3016 15.7145 10.3391 15.6413 10.3391ZM14.2868 9.25156L15.2752 9.47656V8.38906L13.8475 8.46406L14.2868 9.25156Z"
        fill="currentColor"
      />
      <path
        d="M9.82056 16.3372H7.00181C6.78217 16.3372 6.63574 16.1872 6.63574 15.9622C6.63574 15.7372 6.78217 15.5872 7.00181 15.5872H9.82056C10.1134 15.5872 10.3331 15.4372 10.4795 15.1747L12.3831 11.3122C12.4563 11.1247 12.6759 11.0497 12.859 11.1622C13.042 11.2372 13.1152 11.4622 13.0054 11.6497L11.1018 15.5122C10.8822 15.9997 10.3697 16.3372 9.82056 16.3372Z"
        fill="currentColor"
      />
      <path
        d="M9.78371 14.875H7.62388C7.40424 14.875 7.25781 14.725 7.25781 14.5C7.25781 14.275 7.40424 14.125 7.62388 14.125H9.78371C10.0033 14.125 10.1498 14.275 10.1498 14.5C10.1498 14.725 10.0033 14.875 9.78371 14.875Z"
        fill="currentColor"
      />
      <path
        d="M10.0406 13.6367H8.0272C7.80756 13.6367 7.66113 13.4867 7.66113 13.2617C7.66113 13.0367 7.80756 12.8867 8.0272 12.8867H10.0406C10.2602 12.8867 10.4067 13.0367 10.4067 13.2617C10.4067 13.4867 10.2602 13.6367 10.0406 13.6367Z"
        fill="currentColor"
      />
      <path
        d="M10.1503 5.50273C9.93061 5.50273 9.78418 5.35273 9.78418 5.12773V3.74023C9.78418 3.51523 9.93061 3.36523 10.1503 3.36523C10.3699 3.36523 10.5163 3.51523 10.5163 3.74023V5.12773C10.5163 5.35273 10.3333 5.50273 10.1503 5.50273Z"
        fill="currentColor"
      />
      <path
        d="M11.5047 6.96367C11.3217 6.96367 11.1752 6.85117 11.1386 6.66367L10.8092 4.82617H10.1136C9.89399 4.82617 9.74756 4.67617 9.74756 4.45117C9.74756 4.22617 9.89399 4.07617 10.1136 4.07617H11.1386C11.3217 4.07617 11.4681 4.18867 11.5047 4.37617L11.8708 6.51367C11.9074 6.70117 11.761 6.92617 11.5779 6.96367C11.5779 6.96367 11.5413 6.96367 11.5047 6.96367Z"
        fill="currentColor"
      />
      <path
        d="M5.53804 9.88789H1.95054C1.7309 9.88789 1.58447 9.73789 1.58447 9.51289V4.60039C1.58447 3.73789 2.28001 3.02539 3.12197 3.02539H4.2934C5.13536 3.02539 5.8309 3.73789 5.8309 4.60039V9.55039C5.90412 9.73789 5.72108 9.88789 5.53804 9.88789ZM2.31662 9.13789H5.13537V4.60039C5.13537 4.15039 4.76929 3.77539 4.33001 3.77539H3.15858C2.71929 3.77539 2.35322 4.15039 2.35322 4.60039V9.13789H2.31662Z"
        fill="currentColor"
      />
      <path
        d="M4.51246 3.73945H2.97496C2.75532 3.73945 2.60889 3.58945 2.60889 3.36445V1.63945C2.60889 1.07695 3.04817 0.626953 3.59728 0.626953H3.85353C4.40264 0.626953 4.84192 1.07695 4.84192 1.63945V3.36445C4.87853 3.58945 4.69549 3.73945 4.51246 3.73945ZM3.34103 2.98945H4.10978V1.63945C4.14639 1.48945 4.03657 1.37695 3.89014 1.37695H3.59728C3.45085 1.37695 3.34103 1.48945 3.34103 1.63945V2.98945Z"
        fill="currentColor"
      />
      <path
        d="M6.81886 12.0617H0.81529C0.595647 12.0617 0.449219 11.9117 0.449219 11.6867V9.51172C0.449219 9.28672 0.595647 9.13672 0.81529 9.13672H6.81886C7.0385 9.13672 7.18493 9.28672 7.18493 9.51172V11.6867C7.18493 11.9117 7.0385 12.0617 6.81886 12.0617ZM1.18136 11.3117H6.45279V9.88672H1.18136V11.3117Z"
        fill="currentColor"
      />
      <path
        d="M5.42803 15.7375H2.2066C1.58428 15.7375 1.07178 15.2125 1.07178 14.575V11.6875C1.07178 11.4625 1.21821 11.3125 1.43785 11.3125H6.23338C6.45303 11.3125 6.59946 11.4625 6.59946 11.6875V14.575C6.56285 15.2125 6.05035 15.7375 5.42803 15.7375ZM1.80392 12.0625V14.575C1.80392 14.8 1.98696 14.9875 2.2066 14.9875H5.42803C5.64767 14.9875 5.83071 14.8 5.83071 14.575V12.0625H1.80392V12.0625Z"
        fill="currentColor"
      />
      <path
        d="M4.95148 17.9152H2.68184C2.24255 17.9152 1.91309 17.5402 1.91309 17.1277V15.3652C1.91309 15.1402 2.05951 14.9902 2.27916 14.9902H5.39076C5.61041 14.9902 5.75684 15.1402 5.75684 15.3652V17.1277C5.75684 17.5777 5.39076 17.9152 4.95148 17.9152ZM2.60862 15.7402V17.1277C2.60862 17.1652 2.64523 17.1652 2.64523 17.1652H4.91487C4.95148 17.1652 4.95148 17.1277 4.95148 17.1277V15.7402H2.60862Z"
        fill="currentColor"
      />
      <path
        d="M4.03647 19.6762H3.12129C2.682 19.6762 2.35254 19.3012 2.35254 18.8887V17.5762C2.35254 17.3512 2.49897 17.2012 2.71861 17.2012C2.93825 17.2012 3.08468 17.3512 3.08468 17.5762V18.8887C3.08468 18.9262 3.12129 18.9262 3.12129 18.9262H4.03647C4.25611 18.9262 4.40254 19.0762 4.40254 19.3012C4.40254 19.5262 4.25611 19.6762 4.03647 19.6762Z"
        fill="currentColor"
      />
      <path
        d="M5.28097 12.0617H2.3524C2.13276 12.0617 1.98633 11.9117 1.98633 11.6867V9.51172C1.98633 9.28672 2.13276 9.13672 2.3524 9.13672H5.28097C5.50061 9.13672 5.64704 9.28672 5.64704 9.51172V11.6867C5.64704 11.9117 5.46401 12.0617 5.28097 12.0617ZM2.71847 11.3117H4.9149V9.88672H2.71847V11.3117V11.3117Z"
        fill="currentColor"
      />
      <path
        d="M3.81724 18.7387C3.5976 18.7387 3.45117 18.5887 3.45117 18.3637V17.5762C3.45117 17.3512 3.5976 17.2012 3.81724 17.2012C4.03689 17.2012 4.18331 17.3512 4.18331 17.5762V18.3637C4.18331 18.5512 4.03689 18.7387 3.81724 18.7387Z"
        fill="currentColor"
      />
      <path
        d="M12.2367 11.8404H8.42952C8.3197 11.8404 8.20988 11.7654 8.13666 11.6904C8.06345 11.6154 8.06345 11.4654 8.10006 11.3529L8.17327 11.0904L7.84381 10.8654C7.66077 10.7529 7.62416 10.5279 7.73399 10.3404C7.84381 10.1529 8.06345 10.1154 8.24649 10.2279L8.79559 10.6029C8.94202 10.6779 8.97863 10.8654 8.94202 11.0154L8.90541 11.0904H12.2001C12.859 11.0904 13.4081 10.5279 13.4081 9.8529V9.8154C13.4081 9.4404 13.2983 8.9154 12.9688 8.6904C12.7126 8.5029 12.3465 8.46539 11.8706 8.61539L8.10006 9.74039C7.91702 9.81539 7.69738 9.6654 7.66077 9.4779C7.58756 9.2904 7.69738 9.0654 7.91702 9.0279L11.651 7.90289C12.3465 7.67789 12.9322 7.71539 13.4081 8.09039C13.9572 8.5029 14.1402 9.2529 14.1402 9.8154V9.8529C14.1768 10.9404 13.2983 11.8404 12.2367 11.8404Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3400_15025">
        <rect
          width="19.9509"
          height="19.05"
          fill="translate"
          transform="translate(0.449219 0.625)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PackageIconoServiciosCambioFrentos;
