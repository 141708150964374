import React from "react";

const PackageIconoServiciosAfinacion = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M5.1771 7.92094C5.12892 7.92123 5.08117 7.91177 5.03656 7.89311C4.99196 7.87445 4.9514 7.84695 4.91719 7.81219L3.62496 6.48844C3.59037 6.45371 3.56285 6.4123 3.544 6.36659C3.52515 6.32089 3.51534 6.27181 3.51514 6.22219C3.51534 6.17258 3.52515 6.1235 3.544 6.07779C3.56285 6.03209 3.59037 5.99067 3.62496 5.95594L6.47299 3.03844C6.54118 2.96916 6.63321 2.93011 6.72924 2.92969V2.92969C6.77742 2.92941 6.82518 2.93887 6.86978 2.95753C6.91438 2.97619 6.95495 3.00369 6.98915 3.03844L8.27407 4.36594C8.34225 4.4362 8.38052 4.53125 8.38052 4.63032C8.38052 4.72939 8.34225 4.82443 8.27407 4.89469L5.42969 7.81219C5.36241 7.88057 5.27187 7.91956 5.1771 7.92094ZM4.40103 6.22219L5.1771 7.01719L7.50532 4.63219L6.72924 3.83719L4.40103 6.22219Z"
      fill="currentColor"
    />
    <path
      d="M12.9415 15.8783C12.845 15.8769 12.7529 15.8364 12.6853 15.7658L11.3894 14.442C11.3554 14.407 11.3286 14.3654 11.3104 14.3197C11.2922 14.274 11.2829 14.2251 11.2832 14.1758C11.2836 14.0774 11.3217 13.9831 11.3894 13.9133L14.2374 10.9958C14.306 10.9259 14.3988 10.8867 14.4955 10.8867C14.5922 10.8867 14.685 10.9259 14.7536 10.9958L16.0495 12.3195C16.0838 12.3544 16.111 12.3959 16.1296 12.4415C16.1482 12.4872 16.1577 12.5363 16.1577 12.5858C16.1577 12.6353 16.1482 12.6843 16.1296 12.73C16.111 12.7757 16.0838 12.8172 16.0495 12.852L13.2014 15.7508C13.1688 15.7889 13.1289 15.8199 13.0842 15.8418C13.0396 15.8637 12.991 15.8761 12.9415 15.8783ZM12.1654 14.1758L12.9415 14.9708L15.2734 12.5858L14.4973 11.7908L12.1654 14.1758Z"
      fill="currentColor"
    />
    <path
      d="M14.7542 16.1266C14.6581 16.1261 14.5661 16.0871 14.4979 16.0178L13.4619 14.9566C13.3937 14.8863 13.3555 14.7912 13.3555 14.6922C13.3555 14.5931 13.3937 14.4981 13.4619 14.4278L14.7542 13.1265C14.7882 13.0914 14.8287 13.0635 14.8733 13.0445C14.9179 13.0254 14.9657 13.0156 15.0141 13.0156C15.0624 13.0156 15.1102 13.0254 15.1548 13.0445C15.1995 13.0635 15.2399 13.0914 15.274 13.1265L16.3283 14.1765C16.3626 14.2114 16.3898 14.2529 16.4084 14.2986C16.427 14.3443 16.4365 14.3933 16.4365 14.4428C16.4365 14.4923 16.427 14.5413 16.4084 14.587C16.3898 14.6327 16.3626 14.6742 16.3283 14.7091L15.0324 16.0328C14.9948 16.0672 14.9507 16.0933 14.9028 16.1094C14.8549 16.1256 14.8043 16.1314 14.7542 16.1266ZM14.238 14.6941L14.7542 15.2228L15.5302 14.4278L15.0141 13.8953L14.238 14.6941Z"
      fill="currentColor"
    />
    <path
      d="M16.6023 17.5071C16.5611 17.5066 16.5202 17.499 16.4815 17.4846L14.1313 16.6558C14.0721 16.6353 14.0191 16.5995 13.9772 16.552C13.9353 16.5045 13.906 16.4468 13.892 16.3844C13.8781 16.322 13.88 16.2569 13.8975 16.1955C13.915 16.134 13.9476 16.0782 13.9922 16.0333L16.3277 13.6483C16.3716 13.6036 16.4259 13.5709 16.4854 13.5535C16.5449 13.5361 16.6078 13.5344 16.6682 13.5486C16.7285 13.5629 16.7844 13.5925 16.8304 13.6349C16.8765 13.6773 16.9114 13.7309 16.9318 13.7908L17.7444 16.2133C17.7643 16.2781 17.7666 16.3472 17.7512 16.4133C17.7357 16.4793 17.703 16.5398 17.6566 16.5883L16.8805 17.3833C16.8455 17.4231 16.8025 17.4547 16.7545 17.4761C16.7065 17.4974 16.6546 17.508 16.6023 17.5071V17.5071ZM14.911 16.1271L16.5035 16.6896L16.9574 16.2246L16.4119 14.5933L14.911 16.1271Z"
      fill="currentColor"
    />
    <path
      d="M11.13 15.0834C11.0334 15.082 10.9413 15.0415 10.8737 14.9709L4.40158 8.34461C4.36765 8.30957 4.34081 8.26801 4.32259 8.22232C4.30437 8.17664 4.29514 8.12771 4.29542 8.07836C4.29514 8.02901 4.30437 7.98008 4.32259 7.93439C4.34081 7.88871 4.36765 7.84715 4.40158 7.81211L8.27461 3.83711C8.3422 3.76645 8.4343 3.72602 8.53086 3.72461C8.5793 3.72482 8.62721 3.73487 8.67182 3.75418C8.71644 3.77349 8.75687 3.80168 8.79077 3.83711L15.2739 10.4634C15.3082 10.4982 15.3354 10.5397 15.354 10.5854C15.3726 10.6311 15.3822 10.6801 15.3822 10.7296C15.3822 10.7791 15.3726 10.8281 15.354 10.8738C15.3354 10.9195 15.3082 10.961 15.2739 10.9959L11.3899 14.9709C11.356 15.0063 11.3155 15.0345 11.2709 15.0538C11.2263 15.0731 11.1784 15.0832 11.13 15.0834V15.0834ZM5.17765 8.07836L11.13 14.1759L14.4978 10.7296L8.54184 4.63211L5.17765 8.07836Z"
      fill="currentColor"
    />
    <path
      d="M18.5423 18.6983C18.4939 18.6981 18.446 18.688 18.4014 18.6687C18.3567 18.6494 18.3163 18.6212 18.2824 18.5858L17.4771 17.7608C17.4089 17.6905 17.3706 17.5955 17.3706 17.4964C17.3706 17.3973 17.4089 17.3023 17.4771 17.232C17.5111 17.1969 17.5516 17.169 17.5962 17.1499C17.6408 17.1309 17.6886 17.1211 17.737 17.1211C17.7853 17.1211 17.8331 17.1309 17.8777 17.1499C17.9224 17.169 17.9628 17.1969 17.9969 17.232L18.8022 18.057C18.8539 18.1095 18.8891 18.1765 18.9034 18.2495C18.9177 18.3225 18.9104 18.3983 18.8825 18.4671C18.8547 18.5359 18.8074 18.5946 18.7468 18.6358C18.6862 18.6769 18.615 18.6987 18.5423 18.6983V18.6983Z"
      fill="currentColor"
    />
    <path
      d="M19.8378 17.3688H19.7573L18.4358 17.0725C18.386 17.0654 18.3381 17.0478 18.2952 17.0208C18.2524 16.9939 18.2153 16.9581 18.1865 16.9159C18.1577 16.8736 18.1377 16.8257 18.1278 16.7752C18.1179 16.7247 18.1182 16.6726 18.1288 16.6222C18.1394 16.5718 18.16 16.5242 18.1894 16.4824C18.2187 16.4405 18.2562 16.4053 18.2994 16.3789C18.3427 16.3526 18.3907 16.3356 18.4407 16.3291C18.4906 16.3227 18.5413 16.3268 18.5895 16.3413L19.9147 16.6338C20.0118 16.6442 20.1009 16.6937 20.1623 16.7715C20.2238 16.8492 20.2526 16.9487 20.2424 17.0482C20.2322 17.1476 20.1838 17.2388 20.108 17.3018C20.0321 17.3647 19.9349 17.3942 19.8378 17.3838V17.3688Z"
      fill="currentColor"
    />
    <path
      d="M17.2465 20.0203C17.1614 20.0222 17.0783 19.9937 17.0114 19.9396C16.9446 19.8856 16.8983 19.8093 16.8804 19.724L16.6022 18.374C16.5818 18.2746 16.6009 18.1709 16.6551 18.0858C16.7093 18.0007 16.7943 17.9412 16.8914 17.9203C16.9885 17.8994 17.0897 17.9189 17.1728 17.9744C17.2559 18.03 17.314 18.1171 17.3344 18.2165L17.6199 19.5703C17.6406 19.6672 17.623 19.7687 17.5708 19.8523C17.5187 19.936 17.4363 19.995 17.3417 20.0165C17.3104 20.023 17.2782 20.0242 17.2465 20.0203V20.0203Z"
      fill="currentColor"
    />
    <path
      d="M19.3187 19.4922C19.5209 19.4922 19.6848 19.3243 19.6848 19.1172C19.6848 18.9101 19.5209 18.7422 19.3187 18.7422C19.1165 18.7422 18.9526 18.9101 18.9526 19.1172C18.9526 19.3243 19.1165 19.4922 19.3187 19.4922Z"
      fill="currentColor"
    />
    <path
      d="M20.1639 20.3574C20.3661 20.3574 20.53 20.1895 20.53 19.9824C20.53 19.7753 20.3661 19.6074 20.1639 19.6074C19.9617 19.6074 19.7979 19.7753 19.7979 19.9824C19.7979 20.1895 19.9617 20.3574 20.1639 20.3574Z"
      fill="currentColor"
    />
    <path
      d="M21.0096 21.2207C21.2118 21.2207 21.3757 21.0528 21.3757 20.8457C21.3757 20.6386 21.2118 20.4707 21.0096 20.4707C20.8075 20.4707 20.6436 20.6386 20.6436 20.8457C20.6436 21.0528 20.8075 21.2207 21.0096 21.2207Z"
      fill="currentColor"
    />
    <path
      d="M17.638 21.2168C17.8402 21.2168 18.0041 21.0489 18.0041 20.8418C18.0041 20.6347 17.8402 20.4668 17.638 20.4668C17.4359 20.4668 17.272 20.6347 17.272 20.8418C17.272 21.0489 17.4359 21.2168 17.638 21.2168Z"
      fill="currentColor"
    />
    <path
      d="M17.9457 22.4004C18.1478 22.4004 18.3117 22.2325 18.3117 22.0254C18.3117 21.8183 18.1478 21.6504 17.9457 21.6504C17.7435 21.6504 17.5796 21.8183 17.5796 22.0254C17.5796 22.2325 17.7435 22.4004 17.9457 22.4004Z"
      fill="currentColor"
    />
    <path
      d="M21.0028 17.7676C21.205 17.7676 21.3689 17.5997 21.3689 17.3926C21.3689 17.1855 21.205 17.0176 21.0028 17.0176C20.8006 17.0176 20.6367 17.1855 20.6367 17.3926C20.6367 17.5997 20.8006 17.7676 21.0028 17.7676Z"
      fill="currentColor"
    />
    <path
      d="M22.1551 18.082C22.3573 18.082 22.5212 17.9141 22.5212 17.707C22.5212 17.4999 22.3573 17.332 22.1551 17.332C21.953 17.332 21.7891 17.4999 21.7891 17.707C21.7891 17.9141 21.953 18.082 22.1551 18.082Z"
      fill="currentColor"
    />
    <path
      d="M12.6157 8.62305C12.5196 8.62263 12.4276 8.58358 12.3594 8.5143L11.2612 7.3893C11.2266 7.35457 11.1991 7.31315 11.1802 7.26745C11.1614 7.22174 11.1516 7.17266 11.1514 7.12305V4.49805C11.1514 4.39859 11.1899 4.30321 11.2586 4.23288C11.3272 4.16256 11.4204 4.12305 11.5174 4.12305H14.0799C14.177 4.12305 14.2701 4.16256 14.3388 4.23288C14.4074 4.30321 14.446 4.39859 14.446 4.49805V6.74805C14.4463 6.7974 14.4371 6.84632 14.4188 6.89201C14.4006 6.9377 14.3738 6.97926 14.3398 7.0143L12.8756 8.5143C12.8414 8.54905 12.8008 8.57655 12.7562 8.59521C12.7116 8.61387 12.6638 8.62333 12.6157 8.62305ZM11.8835 6.9693L12.6157 7.7193L13.7139 6.5943V4.87305H11.8835V6.9693Z"
      fill="currentColor"
    />
  </svg>
);

export default PackageIconoServiciosAfinacion;
